import React, { useState, useEffect } from "react";
import JobCard from "../components/JobCard";

function JobListPage() {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [jobTitleKeyword, setJobTitleKeyword] = useState("");
  const [jobType, setJobType] = useState(""); // New state for job type filter
  const jobsPerPage = 10;

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "GET",
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };
    fetchJobs();
  }, []);

  // Filter jobs based on selected company, job title keyword, and job type
  const filteredJobs = jobs.filter((job) => {
    const matchesCompany =
      selectedCompany === "" || job.companyName === selectedCompany;
    const matchesKeyword = job.jobTitle
      .toLowerCase()
      .includes(jobTitleKeyword.toLowerCase());

    const matchesJobType =
      jobType === ""
        ? true
        : jobType === "Internship"
        ? job.jobTitle.toLowerCase().includes("intern")
        : !job.jobTitle.toLowerCase().includes("intern");

    return matchesCompany && matchesKeyword && matchesJobType;
  });

  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const uniqueCompanies = Array.from(
    new Set(jobs.map((job) => job.companyName))
  );

  const renderPagination = () => {
    const pages = [];

    if (totalPages <= 1) return null;

    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
          style={styles.pageButton}
        >
          Previous
        </button>
      );
    }

    // Always show the first page
    pages.push(
      <button
        key="1"
        onClick={() => handlePageChange(1)}
        style={{
          ...styles.pageButton,
          ...(currentPage === 1 && styles.pageButtonActive),
        }}
      >
        1
      </button>
    );

    if (currentPage > 3) {
      pages.push(
        <span key="dots-before" style={styles.ellipsis}>
          ...
        </span>
      );
    }

    // Show 2 pages before the current page
    for (
      let i = Math.max(2, currentPage - 2);
      i <= Math.min(currentPage + 2, totalPages - 1);
      i++
    ) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          style={{
            ...styles.pageButton,
            ...(currentPage === i && styles.pageButtonActive),
          }}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages - 2) {
      pages.push(
        <span key="dots-after" style={styles.ellipsis}>
          ...
        </span>
      );
    }

    // Always show the last page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          style={{
            ...styles.pageButton,
            ...(currentPage === totalPages && styles.pageButtonActive),
          }}
        >
          {totalPages}
        </button>
      );
    }

    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
          style={styles.pageButton}
        >
          Next
        </button>
      );
    }

    return pages;
  };

  return (
    <div style={styles.container}>
      {/* Static Text */}
      <p style={styles.infoText}>
        Showing <span style={styles.jobUpdate}>Jobs posted in last 1 week</span>
        , updated 14th March
      </p>

      <h2>Job Openings</h2>

      {/* Filter Section */}
      <div style={styles.filterContainer}>
        <select
          value={selectedCompany}
          onChange={(e) => setSelectedCompany(e.target.value)}
          style={styles.filterDropdown}
        >
          <option value="">All Companies</option>
          {uniqueCompanies.map((company) => (
            <option key={company} value={company}>
              {company}
            </option>
          ))}
        </select>

        <input
          type="text"
          value={jobTitleKeyword}
          onChange={(e) => setJobTitleKeyword(e.target.value)}
          placeholder="Search job title..."
          style={styles.searchInput}
        />

        {/* Job Type Filter */}
        <select
          value={jobType}
          onChange={(e) => setJobType(e.target.value)}
          style={styles.filterDropdown}
        >
          <option value="">All Job Types</option>
          <option value="Full-time">Full-time</option>
          <option value="Internship">Internship</option>
        </select>
      </div>

      {/* Job List */}
      <div style={styles.jobList}>
        {currentJobs.map((job) => (
          <JobCard
            key={job.index}
            title={job.jobTitle}
            location={job.location}
            jobId={job.jobId}
            link={job.jobLink}
            company={job.companyName}
          />
        ))}
      </div>

      {/* Pagination */}
      <div style={styles.pagination}>{renderPagination()}</div>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  infoText: {
    textAlign: "center",
    fontSize: "1.1rem",
    marginBottom: "20px",
    color: "#555",
  },
  jobUpdate: {
    color: "#FF5A5F",
    fontWeight: "bold",
  },
  filterContainer: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "white",
  },
  filterDropdown: {
    padding: "12px 15px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    color: "#333",
    outline: "none",
    transition: "border-color 0.2s ease, box-shadow 0.2s ease",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "500",
  },
  searchInput: {
    padding: "12px 15px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
    flex: "1",
    outline: "none",
    transition: "border-color 0.2s ease, box-shadow 0.2s ease",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  },
  jobList: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  pageButton: {
    margin: "0 5px",
    padding: "8px 12px",
    fontSize: "1rem",
    backgroundColor: "black",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  pageButtonActive: {
    backgroundColor: "#FF5A5F",
  },
  ellipsis: {
    margin: "0 5px",
    color: "#666",
  },
};

export default JobListPage;
